import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-1/2 transform -translate-x-1/2 -translate-y-2/3 h-32 w-32" }
const _hoisted_2 = { class: "mt-6 mx-auto w-full text-2xl text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ 'transition-delay': _ctx.transitionDelay }),
    class: "w-full h-full z-40"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UiIcon, {
        size: "icon-xl",
        class: "transition-transform animate-spin mx-auto",
        name: "loading"
      }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('LOADING_SPINNER_TITLE')), 1)
    ])
  ], 4))
}