
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    transitionDelay: {
      type: String,
      required: false,
      default: '0.3s',
    },
  },
})
